let timeout;

module.exports = function debounce(callback, wait) {
  const executedFunction = (...args) => {
    const later = () => {
      timeout = null;
      // Execute the callback
      callback(...args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };

  const cancelDebounce = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  };
  return [executedFunction, cancelDebounce];
}
