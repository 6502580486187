require('./bootstrap');

let dataPac = null;

/**
 * Remover atributo required a checkbox o radiobutton que no esten seleccionados en caso contrario agregar el atributo a todos los elementos
 * @param {Element} elms Elemento jquery con los nodos
 */
const remogeAttrRequired = (elms) => {
  if (elms.is(':checked')) {
    elms.removeAttr('required');
  } else {
    elms.attr('required', 'required');
  }
}

const requiredChecked = (elmGroup) => {
  elmGroup.change(function () {
    remogeAttrRequired(elmGroup);
  });
}

$(function () {
  const poblacionalCheckboxes = $('.row-grupo-poblacional :checkbox[required]');
  requiredChecked(poblacionalCheckboxes);

  const sintomasCheckboxes = $('.row-sintomas :checkbox[required]');
  requiredChecked(sintomasCheckboxes);

  const antecedentesCheckboxes = $('.row-antecedentes :checkbox[required]');
  requiredChecked(antecedentesCheckboxes);

  const complicacionesCheckboxes = $('.row-complicaciones :checkbox[required]');
  requiredChecked(complicacionesCheckboxes);
});

const elmArray = (data) => {
  try {
    _.map(data, (elmKey) => {
      const elm = $(`#${elmKey}`);
      if (elmKey === 'en-calidad-de-acudiente'
        || elmKey === 'en-calidad-de-paciente' 
        || elmKey ==='en-calidad-de-padMad') {
        $(".acudiente-acu-pac").removeClass("d-none");
        selectAcuPac(elmKey);
      }
      elm.prop('checked', true);
    })
  } catch (error) {
    console.error('elmArray', error);
  }
}

const dataElm = async (arr, key = null) => {
  try {
    elmArray(arr);
  } catch (error) {

  } finally {
    if (key) {
      const elm = $(`.row-${key} :checkbox[required]`);
      remogeAttrRequired(elm);
    }
  }
}

const updateValelm = ({ data_form }) => {
  try {
    _.map(data_form, (val, key) => {
      if (val instanceof Array) {
        dataElm(val, key);
      } else {
        const elm = $(`input[name=${key}]`);
        if (elm.length > 1) {
          dataElm([val]);
        } else {
          elm.val(val);
        }
      }
    })
  } catch (error) {
  } finally {
    dataPac = data_form;
  }

}

const resetForm = (data) => {
  // const typeDoc = $(`[name="tipo-documento"]:checked`);
  // $('#form-ficha-covid')[0].reset();
  // $("#numero-documento").val(data.num_doc);
  // console.log(typeDoc[0])
  // typeDoc.attr('checked', true);
  dataPac = null;
  $('#form-ficha-covid input:text:not(".ignore")').val('');
  $('#form-ficha-covid input[type="number"]:not(".ignore")').val('');
  $('#form-ficha-covid input[type="date"]:not(".ignore")').val('');
  $('#form-ficha-covid input[type="radio"]:not(".ignore")').removeAttr("checked");
  $('#form-ficha-covid input[type="checkbox"]:not(".ignore")').removeAttr("checked");
  // $('#form-ficha-covid input:radio:not(".ignore")')
  // .attr('checked', true);
}

const btnSearchInactive = (act) => {
  if (!act) {
    $("#bnt-search-ficha, #numero-documento")
      .removeAttr('disabled');
    $("#bnt-search-ficha")
      .text('Buscar');
  } else {
    $("#bnt-search-ficha, #numero-documento")
      .attr('disabled', '');
    $("#bnt-search-ficha")
      .text('Buscando...');
  }
}

const updateForm = (data) => {
  try {
    const params = queryString.stringify(data);
    resetForm(data);
    btnSearchInactive(true);
    axios.get(`/api/get-ficha-covid?${params}`)
      .then(function (response) {
        const { patien, consentimiento } = response.data.data ?? {};
        if (patien) {
          updateValelm(patien);
        } else {
          alertify.warning('Sin resultados');
          resetForm(data);
        }
        __consentimiento(consentimiento);
        btnSearchInactive(false);
      })
      .catch(function (error, response) {
        console.error(error);
        btnSearchInactive(false);
      });
  } catch (error) {
    console.error('updateForm: ', error);
    btnSearchInactive(false);
  }
}

const execGetPatien = () => {
  const docType = $('input[name=tipo-documento]:checked');
  const numDoc = $("#numero-documento");

  if (numDoc.val()) {
    updateForm({
      type_doc: docType.val(),
      num_doc: numDoc.val(),
    });
  }
};

const selectAcuPac = (acuPac) => {
  if (acuPac === 'en-calidad-de-paciente') {
    $(".acudiente-acu-pac").addClass("d-none");
  } else if (acuPac === 'en-calidad-de-acudiente' || acuPac === 'en-calidad-de-padMad') {
    $(".acudiente-acu-pac").removeClass("d-none");
  }
}

$('.select-pac').on('click', (e) => {
  selectAcuPac('en-calidad-de-paciente');
});

$('.select-acu').on('click', (e) => {
  $(".acudiente-acu-pac").removeClass("d-none");
  selectAcuPac('en-calidad-de-acudiente');
});

const sendForm = ($form) => {
  const dataform = $form.serialize();
  axios.post('/api/ficha-covid', dataform)
    .then(function (response) {
      alertify.success('Registro Exitoso');
      const { consentimiento } = response.data.data ?? {};
      dataPac =  queryString.parse(dataform);
      __consentimiento(consentimiento);
    })
    .catch(function (error) {
      __consentimiento(false);
      console.log(error);
    });
}

const errorForm = ($form) => {
  $form.addClass('valid-form');
  $('html, body').animate({
    scrollTop: $(".error").offset().top - 130
  }, 2000);
}

$("input[name=tipo-documento]").change(execGetPatien);
$("#numero-documento").keypress((e) => {
  if (e.which == 13) {
    execGetPatien();
  }
});
$("#bnt-search-ficha").click(execGetPatien);
// btn-ficha-covid form-ficha-covid
$('#btn-ficha-covid').click((e) => {
  const $form = $('#form-ficha-covid');

  $form.valid() ? sendForm($form) : errorForm($form);
});


//
$("#btn-ficha-consentimiento").on('click', (e) => {
  try {
    axios.post('/api/pdf-covid-consentimiento', { dataPac })
      .then(function (response) {
        const $id = response.data.data;
        const page = `/covid-consen?num_doc=${$id}`;
        const myWindow = window.open(page, "_blank");
        myWindow.focus();
      })
      .catch(function (error) {
        console.log(error);
      });
  } catch (error) {
    console.log(" ", error)
  }

});

const __consentimiento = (consen) => {
  if (consen) {
    $("#btn-ficha-consentimiento").css({
      display: 'block'
    });
  } else {
    $("#btn-ficha-consentimiento").css({
      display: 'none'
    });
  }
}

$('.btn-logout').click(() => {
  $('#logout-form').submit();
});

// Estas guías están basadas en el estandar de desarrollo
// para moviles con pantalla de ~5"

// const lineBase = {
//   height: 350,
//   width: 680,
// }

// const {width, height} = Dimensions.get('window');

// export const scaleBase = (lineBaseHeight = lineBase.height, lineBaseWidth = lineBase.width) => {
//   return {
//     scale: size => width / lineBaseWidth * size,
//     verticalScale: size => height / lineBaseHeight * size,
//     moderateScale: (size, factor = 0.5) => size + (scale(size) - size) * factor,
//   };
// }
